import * as React from "react"
import { graphql } from "gatsby"

import Layout from "./layout2022"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="221229" />
      <h2 className="font-title-sub text-center">Tokyo Photo Dump</h2>
      <div className="text-center pb-4">
        <StaticImage
          formats={["auto"]}
          src="../../../images/journal/2022/221229_1.jpg"
          width={500}
          quality={95}
        />
      </div>
      <div className="text-center pb-4">
        <StaticImage
          formats={["auto"]}
          src="../../../images/journal/2022/221229_2.jpg"
          width={500}
          quality={95}
        />
      </div>
      <div className="font-text-ja">
        <p>大学のゼミのOBOG会に行くために弾丸東京。集まりに行く前に丸の内を散歩したんだけれど、ちょうど銀杏が見頃でイルミネーションも綺麗だった。</p>
      </div>

      <div className="text-center pb-4">
        <StaticImage
          formats={["auto"]}
          src="../../../images/journal/2022/221229_3.jpg"
          width={500}
          quality={95}
        />
      </div>
      <div className="font-text-ja">
        <p>先述のOBOG会で久しぶりに再会した大学の同期と後輩とで、後日銀座の天龍へ餃子を食べに行った。手のひらくらいの大きさがあって、餃子二皿+エビマヨ+天津飯頼んだらお腹いっぱい。餃子美味しかった！</p>
      </div>

      <div className="text-center pb-4">
        <StaticImage
          formats={["auto"]}
          src="../../../images/journal/2022/221229_4.jpg"
          width={500}
          quality={95}
        />
      </div>
      <div className="font-text-ja">
        <p>気になってたノルウェー発祥のカフェ「FUGLEN TOKYO」へ。午前中に行ったけれど結構混んでて、海外の人が多かったな。本日のコーヒーとデニッシュみたいなパンを注文。コーヒーは浅煎りで自分の好みとは違ったけれど、海外のコーヒースタンドにいる空気で雰囲気は好きだった。</p>
      </div>

      <div className="text-center pb-4">
        <StaticImage
          formats={["auto"]}
          src="../../../images/journal/2022/221229_5.jpg"
          width={500}
          quality={95}
        />
      </div>
      <div className="text-center pb-4">
        <StaticImage
          formats={["auto"]}
          src="../../../images/journal/2022/221229_6.jpg"
          width={500}
          quality={95}
        />
      </div>
      <div className="font-text-ja">
        <p>ベジタリアンの友達とヴィーガンカフェで夜ごはん＆渋谷のイルミネーションの「青の洞窟」へ。ヴィーガンの料理ってどうなんだろうと思ってたんだけれど、この友達と毎回違うレストラン開拓してて、普通に美味しいんだよね。</p>
      </div>

      <div className="text-center pb-4">
        <StaticImage
          formats={["auto"]}
          src="../../../images/journal/2022/221229_7.jpg"
          width={500}
          quality={95}
        />
      </div>
      <div className="text-center pb-4">
        <StaticImage
          formats={["auto"]}
          src="../../../images/journal/2022/221229_8.jpg"
          width={500}
          quality={95}
        />
      </div>
      <div className="font-text-ja">
        <p>高校の友達とクリスマス会。水道橋のケーキ屋さんで買ったレアチーズケーキがすんごく美味しかった、今年食べた中でいちばん！デパ地下でお惣菜調達したり、プレゼント交換したり、楽しかったな。</p>
      </div>

      <div className="text-center pb-4">
        <StaticImage
          formats={["auto"]}
          src="../../../images/journal/2022/221229_9.jpg"
          width={500}
          quality={95}
        />
      </div>
      <div className="font-text-ja">
        <p>高速バスで帰ったんだけれど、どうしても海鮮が食べたくて磯丸水産で海鮮丼をテイクアウトしたんだよね。千円しないし、臭みもなくて、何よりも（新幹線の駅弁のテンションで）バスで食べたのが良かったなぁ。</p>
      </div>

    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
